<template>
  <div>
    <div v-if="currentStep === 1">
      <button class="find-btn" @click="findNearbyPlaces">Find Nearby Places</button>

      <!-- Display message if there is one -->
      <p v-if="message">{{ message }}</p>

      <!-- Display list of places if available -->
      <ul class="places-list" v-if="places.length">
        <li
          v-for="(place, index) in places"
          :key="index"
          @click="selectPlace(index)"
          :class="{ selected: selectedPlace === index }"
        >
          {{ place.displayName.text }}
        </li>
      </ul>

      <!-- Add the Next button -->
      <button class="next-btn" v-if="selectedPlace !== null" @click="nextStep">Next</button>
    </div>

    <div v-if="currentStep === 2">
      <h2>Upload Images for {{ selectedPlaceName }}</h2>

      <input type="file" multiple @change="onFileChange" />
      <p>Uploaded {{ selectedImages.length }} / 5 images</p>
      <ul class="no-bullets">
        <li v-for="(image, index) in selectedImages" :key="index">
          {{ image.name }} 
          <button @click="removeImage(index)">Remove</button>
        </li>
      </ul>
      <div class="button-container">
        <button class="next-btn" @click="prevStep">Back</button>
        <button class="next-btn" @click="submitImages" :disabled="selectedImages.length === 0">Next</button>
      </div>
    </div>

    <div v-if="currentStep === 3">
      <h2>Overview</h2>
      <p>Place: {{ selectedPlaceName }}</p>
      <h3>Images:</h3>
      <ul class="no-bullets">
        <li v-for="(image, index) in selectedImages" :key="index">{{ image.name }}</li>
      </ul>
      <div class="button-container">
        <button class="next-btn" @click="prevStep">Back</button>
        <button class="next-btn" @click="saveToCRM">Save Lead</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      places: [],
      selectedPlace: null, // Track the selected place index
      selectedPlaceName: "",
      currentStep: 1, // Tracking current step
      selectedImages: [], // To store selected images
      message: "",
      organizationId: null // To store created organization ID
    };
  },
  methods: {
    findNearbyPlaces() {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

        try {
          const response = await fetch("https://fastapi-example-g24y.onrender.com/leads/find", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              latitude: latitude,
              longitude: longitude,
            }),
          });

          const data = await response.json();
          console.log("API Response:", data);

          if (typeof data === "string") {
            console.warn("No places found:", data);
            this.places = [];
            this.message = data;
          } else {
            this.places = data.places;
            this.message = "";
          }
        } catch (error) {
          console.error("Error fetching places:", error);
          this.message = "Error fetching places, please try again later.";
        }
      });
    },
    selectPlace(index) {
      this.selectedPlace = index;
      this.selectedPlaceName = this.places[index].displayName.text;
      this.selectedPlacePhone = this.places[index].internationalPhoneNumber;
      this.selectedPlaceWebsite = this.places[index].websiteUri;
    },
    nextStep() {
      this.currentStep++;
    },
    prevStep() {
      this.currentStep--;
    },
    onFileChange(event) {
      const files = event.target.files;
      if (files.length + this.selectedImages.length <= 5) {
        this.selectedImages.push(...files);
      } else {
        alert("You can only upload up to 5 images.");
      }
    },
    removeImage(index) {
      this.selectedImages.splice(index, 1); // Remove image from the array
    },
    async submitImages() {
      if (!this.selectedPlaceName) {
        alert("Please select a place.");
        return;
      }

      // First create the organization in Pipedrive
      const organizationData = {
        name: this.selectedPlaceName,
        phone: this.selectedPlacePhone,
        website: this.selectedPlaceWebsite,
      };

      try {
        const response = await fetch(
          `https://fastapi-example-g24y.onrender.com/create-organization`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(organizationData),
          }
        );

        const data = await response.json();
        console.log("Organization created:", data);

        if (data.success) {
          this.organizationId = data.organization_id; // Store the organization ID for the image upload
          this.nextStep();
        } else {
          alert("Error creating organization.");
        }
      } catch (error) {
        console.error("Error creating organization:", error);
        alert("An error occurred while creating the organization.");
      }
    },
    async saveToCRM() {
      if (!this.organizationId) {
        alert("No organization created.");
        return;
      }

      const formData = new FormData();
      formData.append("organization_id", this.organizationId); // Use the created organization ID
      this.selectedImages.forEach((image) => {
        formData.append("files", image);
      });

      try {
        const response = await fetch(
          `https://fastapi-example-g24y.onrender.com/add-images/${this.organizationId}`,
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();
        if (data.success) {
          alert("Organization and images successfully saved to CRM!");
          // Return to the initial state
          this.resetComponent();
        } else {
          alert("Failed to save organization and images to CRM.");
        }
      } catch (error) {
        console.error("Error saving to CRM:", error);
        alert("Error saving to CRM.");
      }
    },
    resetComponent() {
      // Reset component state
      this.currentStep = 1;
      this.selectedPlace = null;
      this.selectedPlaceName = "";
      this.selectedImages = [];
      this.organizationId = null;
      this.findNearbyPlaces(); // Reload the places list
    }
  },
};
</script>

<style scoped>
.container {
  text-align: center;
  margin: 0 auto;
  max-width: 600px; /* Limit the width of the container */
}

.find-btn, .next-btn, .submit-btn {
  background-color: #42b983;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.find-btn:hover, .next-btn:hover, .submit-btn:hover {
  background-color: #358960;
}

.places-list {
  list-style: none;
  padding: 0;
  max-width: 100%; /* Ensure the list is responsive within the container */
  margin: 0 auto; /* Center the list within the container */
  width: 600px;
}

.places-list li {
  padding: 15px;
  margin: 10px 0;
  border: 2px solid #ddd;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 18px;
  text-align: center;
}

.places-list li:hover {
  background-color: #f1f1f1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.places-list li.selected {
  background-color: #42b983;
  color: white;
  border-color: #42b983;
}

.next-btn {
  display: inline-block;
  margin-top: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.no-bullets {
  list-style-type: none;
  padding: 0;
}
</style>
