<template>
  <div class="dashboard-container">
    <div class="sidebar">
      <nav>
        <ul>
          <li>
            <router-link to="/locations" exact-active-class="router-link-exact-active">
              Locations Map
            </router-link>
          </li>
        </ul>
      </nav>
    </div>

    <div class="content">
      <div class="map-container">
        <GoogleMap
          :api-key="apiKey"
          :center="center"
          :zoom="14"
          mapId="DEMO_MAP_ID"
          style="width: 100%; max-width: 800px; height: 500px; margin: 0 auto;"
        >
          <AdvancedMarker
            v-for="location in locations"
            :key="location.id"
            :options="{
              position: { lat: location.latitude, lng: location.longitude }
            }"
            :pin-options="{ background: '#FBBC04' }"
            @click="centerMapOn(location)"
          />
        </GoogleMap>
      </div>

      <div class="locations-panel">
        <h2>Saved Locations</h2>
        <div class="locations-list">
          <div v-for="location in locations" :key="location.id" class="location-card">
            <h3>{{ location.name }}</h3>
            <p>{{ location.address }}</p>
            <button @click="centerMapOn(location)" class="primary-button">
              Show on Map
            </button>
            <button @click="deleteLocation(location.id)" class="delete-btn">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { GoogleMap, AdvancedMarker } from 'vue3-google-map';

export default {
  name: 'UserDashboard',
  components: {
    GoogleMap,
    AdvancedMarker
  },
  setup() {
    const center = ref({ lat: 48.1633333, lng: 11.584444399999999 });
    const locations = ref([]);
    const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API;

    const loadLocations = async () => {
      try {
        const response = await fetch('https://fastapi-example-g24y.onrender.com/places', {
          credentials: 'include',
        });
        const data = await response.json();
        locations.value = data;
      } catch (error) {
        console.error('Error loading locations:', error);
      }
    };

    const centerMapOn = (location) => {
      center.value = { lat: location.latitude, lng: location.longitude };
    };

    const deleteLocation = async (locationId) => {
      try {
        await fetch(`YOUR_API_ENDPOINT/locations/${locationId}`, {
          method: 'DELETE',
          credentials: 'include',
        });
        locations.value = locations.value.filter(loc => loc.id !== locationId);
      } catch (error) {
        console.error('Error deleting location:', error);
      }
    };

    onMounted(loadLocations);

    return {
      apiKey,
      center,
      locations,
      centerMapOn,
      deleteLocation,
    };
  }
};
</script>

<style scoped>
.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.sidebar {
  width: 100%;
  background: #f5f5f5;
  padding: 20px;
  text-align: center;
}

.content {
  width: 100%;
  max-width: 800px;
}

.map-container {
  margin-bottom: 20px;
}

.locations-panel {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.location-card {
  background: white;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.primary-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
}

.delete-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.router-link-exact-active {
  color: #42b983;
  font-weight: bold;
}
</style>